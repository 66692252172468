import React from "react";

import Layout from "@common/Layout";
import { TopHeader } from "@common/TopHeader";

import { useStaticQuery, graphql } from "gatsby";

import Img from "gatsby-image";
import Footer from "@sections/Footer";
import { Section, Container } from "@components/global";
import styled from "styled-components";

const StandaloneText = styled.div`
  max-width: 991px;
  margin: 0 auto;
  padding: 0 1rem;
  & p {
    margin-bottom: 1rem;
    & strong {
      color: ${(props) => props.theme.color.black.regular};
    }
  }
  margin-bottom: 2rem;
`;

const Header = styled.h2`
  margin-top: 0.5rem;
  text-align: center;
`;

const HeaderH1 = styled.h1`
  margin-top: 0.5rem;
  font-size: 26px;
  text-align: center;
`;

const OfertaPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      wynajemDlugoterminowy: file(
        sourceInstanceName: { eq: "other" }
        name: { eq: "wynajem-dlugoterminowy" }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout
      seo={{
        url: "https://www.najtansza-wypozyczalnia-samochodow.pl/wynajem-dlugoterminowy-samochodow-gdansk",
      }}
    >
      <TopHeader />

      <Section fluid>
        <HeaderH1 style={{ marginBottom: "1.5rem" }}>
          Wynajem długoterminowy samochodów
        </HeaderH1>
        <StandaloneText>
          <p>
            <strong>Potrzebujesz samochodu długoterminowo?</strong>
            <br />
            Mamy na to rozwiązanie bez konieczności kupowania pojazdu. Wynajem
            długoterminowy RENT ME CAR GDAŃSK będzie idealną opcją dla Ciebie.
            Jest to tania i korzystna możliwość najmu pojazdu dzięki, której
            możesz zoptymalizować swoje wydatki na samochody służbowe jako
            przedsiębiorca, a także tanio wynająć samochód na potrzeby własne.
            Masz także możliwość wykupienia pojazdu po zakończeniu trwania
            umowy. RENT ME CAR GRAŃSK zapewnia dostęp do autoryzowanych punktów
            serwisowych na terenie całej Polski, elastyczną umowę oraz brak
            kaucji- nie jest wymagana wpłata własna przy wypożyczaniu pojazdu.
          </p>
          <p>
            <strong>Dlaczego najem długoterminowy?</strong>
            <br />W ramach długoterminowego najmu w najtańszej wypożyczalni
            samochodów RENT ME CAR GDAŃSK oferujemy dostarczenie czystego i w
            pełni zatankowanego samochodu pod wskazany adres. Zobacz jakie to
            proste! Dajemy możliwość serwisowania pojazdu w zakresie wymiany{" "}
            oleju, filtrów, klocków hamulcowych itd, a także sezonową wymianę
            opon. Samochód jest w pełni ubezpieczony, posiada specjalistyczne
            badania techniczne pojazdu. Gwarantujemy całodobową pomoc w
            sytuacjach awaryjnych- w przypadku kolizji czy wypadku drogowego
            oraz auto zastępcze w przypadku gdy wynajmowany pojazd ulegnie
            awarii. Klient ma także możliwość wymiany pojazdu na nowy po 6
            miesiącach najmu.
          </p>
          <p>
            <Img
              loading="eager"
              fluid={data.wynajemDlugoterminowy.childImageSharp.fluid}
              alt="Wynajem długoterminowy samochodów"
            />
          </p>

          <p>
            <strong>Jak to działa?</strong>
            <br />
            Wynajem pojazdów w Gdańsku dla firm odbywa się na prostych zasadach.
            Ceny za wynajem są uzależnione od rodzaju, a także długości okresu
            najmu. Udostępniamy samochody odpowiadające Twoim potrzebom.
            Miesięczny koszt najmu wyliczany jest w oparciu o informacje
            podawane przez klienta jakimi są między innymi: okres najmu,
            interesujący model samochodu, limit kilometrów, liczba kierowców
            uprawnionych do kierowania pojazdem, wiek kierowców oraz
            przeznaczenie wykorzystywania pojazdu. Dajemy możliwość rezerwacji
            kilku samochodów, a w tym przypadku istnieje także możliwość
            negocjacji ceny. Wypożyczalnia samochodów RENT ME CAR GDAŃSK oferuje
            pełne ubezpieczenie pojazdó OC, AC, NW, Assistance jednakże biorąc
            pod uwagę fakt iż najemca nie wpłaca kaucji przy najmie wówczas w
            przypadku uszkodzenia pojazdu obciążony zostaje Opłatą
            Administracyjną w przypadku uszkodzeń spowodowanych z jego winy.
            Wypożyczalnia pojazdów RENT ME CAR GDAŃSK daje także możliwość
            zniesienia udziału własnego w szkodzie poprzez wykupienie jednego z
            dostępnych ubezpieczeń jakie oferujemy.
          </p>

          <p>
            <strong>Od czego zacząć?</strong>
            <br />
            Wynajem zaczyna się w momencie kiedy klient pragnie wypożyczyć
            pojazd na okres powyżej 12 miesięcy. Skontaktuj się z nami, a
            pomożemy Ci dobrać najkorzystniejsze warunki najmu. Nasz dział
            Obsługi Klienta pomoże dobrać optymalną ofertę dla Ciebie i Twoich
            potrzeb.
          </p>

          <p>
            <strong>
              Korzyści płynące z najmu długoterminowego w RENT ME CAR GDAŃSK
            </strong>
            <br />
            Usługa ta jest bardzo podobna do leasingu jednak ma wiele
            korzystniejszych stron. Dużym atutem tego typu najmu jest fakt iż
            koszty eksploatacji pojazdu są po stronie wypożyczalni. Nasza
            doświadczona karda pomoże Ci dobrać odpowiedni model dopasowany do
            Twoich potrzeb. Najtańsza wypożyczalnia jaką jest RENT ME CAR GDAŃSK
            posiada bogatą flotę samochodów dzięki czemu dajemy klientom szeroki
            wybór. Jesteśmy świadomi, że w obecnych czasach wszyscy chcieliby
            tanio, szybko oraz wygodnie podróżować. Nasza firma łączy wszystkie
            te czynniki. Często pojawijącym się problemem jest serwisowanie
            samochodu, przechowywanie opon, żużycie auta przez intensywną
            eksploatację. Wszystkie te problemy rozwiązuje najem długoterminowy
            w RENT ME CAR GDAŃSK, gdzie posiadamy najtańszą ofertę najmu.
            Podpisujesz korzystną dla siebie umowę specjalnie dobraną przez
            naszych konsultantów pod Twoje potrzeby i możesz cieszyć się
            beztroską eksploatacją jednego z wielu dostępnych modeli samolchodów
            z naszej szerokiej oferty. W ramach umowy nie musisz przejmować się
            szukaniem odpowiednikego serwisu gdyż samochód można oddać do
            najbliższego Ci miejsca serwisowania pojazdów. W przypadkach
            awaryjnych ekspresowo dostarczamy Ci samochód zastępczy tak abyś nie
            musiał zwalniać tempa. Załątwiamy wszelkie formalności wraz z całą
            procedur zwązaną ze szkodą komunikacyjną. Klienci korzystający z
            wynajmu długoterminowego samochodów nie ponoszą takiego ryzyka jak w
            przypadku zakupy własnego mienia.
          </p>
          <p>
            Wypożyczanie długoterminowe staję się coraz bardziej popularne
            dlatego warto poszukać firmy, która ma konkurencyjne ceny. Tym na
            rynku może śmiało pochwalić się RENT ME CAR GDAŃSK gdyż jest to
            najtańszy wynajem pojazdów długoterminowo. Nie marnuj więcej czasu i
            odwiedź najbliższy punkt naszej firmy aby dowiedzieć się więcej i
            podpisać z nami umowę najmu długoterminowego.
          </p>
        </StandaloneText>
      </Section>

      <Footer />
    </Layout>
  );
};

export default OfertaPage;
